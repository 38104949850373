import './index.scss'

import React, { Children } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('grid')

export default function Grid ({ children, grid }) {
  return (
    <div {...bem('', `${grid}`)}>
      {Children.map(children, child => (
        <div {...bem('item')}>{child}</div>
      ))}
    </div>
  )
}

Grid.propTypes = {
  children: PropTypes.any.isRequired,
  grid: PropTypes.any,
}

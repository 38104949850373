import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import ports from '../../dummy/ports'
import postalCodes from '../../dummy/postal-codes'
import containerTypes from '../../dummy/container-types'

import Image from '../../components/Image'
import Hero from '../../components/Hero'
import Checkbox from '../../components/Checkbox'
import Grid from '../../components/Grid'
import Link from '../../components/Link'
import Select from '../../components/Select'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Section from '../../components/Section'
import Loader from '../../components/Loader'
import Radio from '../../components/Radio'

import Article, { Text, Title, List } from '../../components/Article'

import gridDesktop from '../../assets/brandbook/images/grid-desktop.svg'
import gridTouch from '../../assets/brandbook/images/grid-touch.svg'
import navigation from '../../assets/brandbook/images/navigation.svg'

export default function Clothing({ data: { hero }, ...props }) {
  return (
    <>
      <Hero {...hero} light title="Interactive elements" />

      <Article>
        <Text>The following are best practises, examples and recommendations for building digital products and services by NCL. </Text>

        <Section full type="transparent">
          <Title level="2">Grid and layout</Title>
          <Text>Start off by evaluating the complexity of what you're going to build, and establish a grid system accordingly. A user interface can become quite cluttered and complex but a strong grid system will help when building the overall layout and content.  </Text>

          <Image src={gridDesktop} />
          <Text>For larger screens use a 12 column horizontal grid. Columns should be based on percentages. Set a gutter of 20px if needed. </Text>

          <Image src={gridTouch} />
          <Text>For smaller screens reduce the number of columns accordingly to screen size. Tablets should use 8 columns while mobile phones works well with 4 columns. </Text>
        </Section>

        <Section full type="transparent">

          <Title level="2">Navigation</Title>
          <Text>Present all prioritized entry points as content rather than building a large tree menu. Use a hamburger if needed. A tab bar can also be introduced on touch devices. </Text>

          <Image src={navigation} />

        </Section>

        <Section full type="transparent">
          <Title level="2">Typography</Title>
          <Text>These are the documented sizes. For more details see <Link to="/brandbook/typography">typography</Link>. </Text>

          <Title level="1">Heading 1</Title>
          <Title level="2">Heading 2</Title>
          <Title level="3">Heading 3</Title>
          <Title level="4">Heading 4</Title>
          <br />
          <Title level="5">Heading 5</Title>

          <br />

          <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pulvinar lorem sit amet nisi dictum, et pulvinar nibh posuere. Nullam viverra ligula scelerisque purus faucibus finibus. Proin sollicitudin dui ac mi maximus ullamcorper. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Text>

          <List
            items={[
              'Lorem ipsum dolor sit. ',
              'Mauris id feugiat dui faucibus. ',
              'Proin sollicitudin acmi. ',
            ]}
          />

        </Section>

        <Section full type="transparent">

          <Title level="2">Basic components</Title>
          <Text>These are built for browser preview. They may all differ depending on framework or application. </Text>

          <Title level="3">Buttons</Title>
          <Text>Buttons with available modifiers. </Text>
          <Button primary>Primary</Button>
          <Button secondary>Secondary</Button>
          <Button warning>Warning</Button>
          <Button tertiary>Tertiary</Button>

          <br /><br />

          <Text>Compact buttons. </Text>

          <Button primary compact>Primary</Button>
          <Button secondary compact>Secondary</Button>
          <Button warning compact>Warning</Button>
          <Button tertiary compact>Tertiary</Button>
          <Button subtle compact>Subtle</Button>

          <br />
          <br />
          <br />

          <Title level="3">Select box</Title>

          <Select
            label="Label"
            block
            autogrow={false}
            options={containerTypes}
          />

          <br />
          <Title level="3">Input</Title>

          <Input
            label="Label"
            type="input"
            placeholder="Placeholder"
          />

          <br />
          <Title level="3">Checkbox</Title>

          <Checkbox
            label="Label"
            value="toggle"
          />
          <Checkbox
            label="Label"
            value="toggle"
          />
          <Checkbox
            label="Label"
            value="toggle"
          />

          <br />
          <br />
          <Title level="3">Radio</Title>

          <Radio
            name="radio"
            label="Label"
            value="internal"
          />

          <Radio
            name="radio"
            label="Label"
            value="external"
          />

        </Section>

        <Section full type="transparent">
          <Title level="2">Spinner</Title>
          <Text>When loading external data we show a preloader. </Text>

          <Loader />
        </Section>

      </Article>
    </>
  )
}

Clothing.propTypes = {
  data: PropTypes.object.isRequired,
}

Clothing.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/fog.jpg/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
